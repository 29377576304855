:root {
  min-height: 100%;
  background: #202226 url('./images/background.jpg');
  background-attachment: fixed;
}

body > * {
  color: #d5d5d5;
}

a {
  color: inherit;
}
